import api from "../axios/axios";

export const getContractCSV = (startDate: any, endDate: any) => {
  return api.get("/contract/csv", {
    params: {
      startDate,
      endDate,
    },
  });
};

export const getContractPDFZip = (startDate: any, endDate: any) => {
  return api.get("/contract/pdf", {
    params: {
      startDate,
      endDate,
    },
  });
};

export const getContractCount = () => {
  return api.get("/contract/count");
};
